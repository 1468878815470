import type { ReactElement } from 'react';
import { Button, Link as MuiLink } from '@mui/material';
import type { ButtonProps } from '@mui/material/Button';
import { isExternalLink } from '@noah-labs/fe-shared-ui-components';
import { LocalizedLink } from './LocalizedLink';

export function LocalizedButton({ children, href, ...props }: ButtonProps): ReactElement | null {
  if (!href) {
    return null;
  }
  if (isExternalLink(href)) {
    return (
      <Button component={MuiLink} href={href} target="_blank" {...props}>
        {children}
      </Button>
    );
  }
  return (
    <Button component={LocalizedLink} href={href} {...props}>
      {children}
    </Button>
  );
}
