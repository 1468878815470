import { useEffect } from 'react';
import { css } from '@emotion/react';
import { navigate, useLocation } from '@gatsbyjs/reach-router';
import { Container, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { SecurityPageQuery } from '../../generated/types';
import { SvgFingerprint } from '../components/animated/SvgFingerprint';
import { useBreakpoints } from '../components/hooks/useBreakpoints';
import { navigateToLocalizedPath } from '../components/i18n/utils';
import { ArrowsDown } from '../components/layout';
import { HomepageTemplate } from '../components/templates';
import { MarkdownTypography } from '../components/typography';
import { SecurityAccordion } from '../modules/security';

function SecurityPage({ data: { content } }: PageProps<SecurityPageQuery>): React.ReactElement {
  const theme = useTheme();
  const { isExtraSmall } = useBreakpoints();
  const location = useLocation();

  useEffect(() => {
    navigateToLocalizedPath(location.pathname, navigate);
  }, [location.pathname]);

  const styles = {
    accordionWrapper: css`
      margin-top: ${theme.spacing(11)};
      ${theme.breakpoints.down('lg')} {
        margin-top: ${theme.spacing(7)};
      }
    `,
    container: css`
      ${theme.breakpoints.down('lg')} {
        padding: ${theme.spacing(0, 3)};
      }
    `,
    svgWrapper: css`
      width: 350px;
      margin: ${theme.spacing(8, 0, -8)};
      ${theme.breakpoints.down('md')} {
        margin: ${theme.spacing(6, 0, -8)};
      }
      ${theme.breakpoints.down('sm')} {
        width: 200px;
      }
    `,
    title: css`
      text-align: center;
    `,
  };

  return (
    <HomepageTemplate backgroundColor="transparent" seo={content?.frontmatter?.seo}>
      <Container disableGutters css={styles.container} maxWidth="md">
        <Stack alignItems="center" justifyContent="center" spacing={4}>
          <div css={styles.svgWrapper}>
            <SvgFingerprint />
          </div>
          <MarkdownTypography
            component="h1"
            css={styles.title}
            variant={isExtraSmall ? 'h2' : 'h3'}
          >
            {content?.frontmatter?.securityTitle}
          </MarkdownTypography>
          <ArrowsDown />
        </Stack>
        <Stack spacing={4}>
          <MarkdownTypography variant={isExtraSmall ? 'bodyHeadingM' : 'bodyHeadingS'}>
            {content?.frontmatter?.securityIntro1}
          </MarkdownTypography>
          <MarkdownTypography color="text.dark" variant="paragraphBodyL">
            {content?.frontmatter?.securityIntro2}
          </MarkdownTypography>
        </Stack>
        <Stack css={styles.accordionWrapper} spacing={3.5}>
          <Typography variant={isExtraSmall ? 'bodyHeadingM' : 'bodyHeadingS'}>Contents</Typography>
          <SecurityAccordion securityPoints={content?.frontmatter?.securityPoints} />
        </Stack>
      </Container>
    </HomepageTemplate>
  );
}

export const query = graphql`
  query SecurityPage($locale: String!) {
    content: markdownRemark(frontmatter: { layout: { eq: "security" }, locale: { eq: $locale } }) {
      frontmatter {
        locale
        seo {
          title
          description
          image
        }
        securityTitle
        securityIntro1
        securityIntro2
        securityPoints {
          id
          pointTitle
          pointIntro
          pointBody
          pointCards {
            id
            pointCardBody
            pointCardLink
            pointCardTitle
          }
        }
      }
    }
  }
`;
export default SecurityPage;
