import { useTheme } from '@mui/material/styles';

export type PpSvgFingerprint = {
  darkBackground?: boolean;
};

export function SvgFingerprint({ darkBackground = false }: PpSvgFingerprint): React.ReactElement {
  const theme = useTheme();

  const { white } = theme.palette.common;
  const { 900: black } = theme.palette.grayscale;

  return (
    <svg fill="none" viewBox="0 0 457 457" xmlns="http://www.w3.org/2000/svg">
      <rect
        fill={darkBackground ? black : white}
        height="456.324"
        width="456.324"
        x=".109"
        y=".488"
      />
      <g clipPath="url(#dark-animated-fingerprint_svg__a)" fill={darkBackground ? white : black}>
        <path d="M241.542 257.007v-15.586c0-6.146-5-11.146-11.145-11.146-6.146 0-11.146 5-11.146 11.146v14.072a68.423 68.423 0 0 1-17.592 45.846l-15.642 17.38a3.557 3.557 0 1 0 5.288 4.76l15.642-17.381a75.529 75.529 0 0 0 19.418-50.605v-14.072a4.037 4.037 0 0 1 4.032-4.032 4.036 4.036 0 0 1 4.031 4.032v15.586a83.535 83.535 0 0 1-21.77 56.309l-15.247 16.772a3.557 3.557 0 0 0 5.264 4.786l15.247-16.772a90.639 90.639 0 0 0 23.62-61.095Z" />
        <path d="M256.714 241.418c0-14.514-11.808-26.322-26.322-26.322s-26.322 11.808-26.322 26.322v14.072a53.267 53.267 0 0 1-13.697 35.693l-11.12 12.357a3.557 3.557 0 0 0 5.288 4.759l11.12-12.356a60.379 60.379 0 0 0 15.523-40.453v-14.072c0-10.592 8.617-19.208 19.208-19.208 10.592 0 19.208 8.616 19.208 19.208v15.586c0 24.653-9.132 48.276-25.716 66.518l-12.865 14.152a3.557 3.557 0 1 0 5.264 4.785l12.865-14.152c17.776-19.554 27.566-44.876 27.566-71.303v-15.586Z" />
        <path d="M271.893 241.415c0-22.883-18.617-41.499-41.499-41.499-22.883 0-41.499 18.616-41.499 41.499v14.073a38.12 38.12 0 0 1-9.801 25.54l-10.013 11.125a3.557 3.557 0 0 0 5.288 4.759l10.013-11.125a45.22 45.22 0 0 0 11.627-30.299v-14.073c0-18.96 15.425-34.385 34.385-34.385 18.96 0 34.384 15.425 34.384 34.385v15.586c0 28.437-10.534 55.686-29.663 76.727l-3.56 3.916a3.557 3.557 0 0 0 5.264 4.786l3.559-3.917c20.322-22.354 31.514-51.301 31.514-81.512v-15.586h.001Z" />
        <path d="M173.717 241.414a3.556 3.556 0 1 0 7.114 0 49.489 49.489 0 0 1 12.245-32.58c7.823-8.948 18.56-14.8 30.233-16.477a3.557 3.557 0 0 0-1.011-7.042c-13.356 1.919-25.636 8.609-34.578 18.837a56.603 56.603 0 0 0-14.003 37.262Zm7.121 14.075a3.557 3.557 0 1 0-7.114 0 22.964 22.964 0 0 1-5.905 15.387l-8.906 9.896a3.557 3.557 0 0 0 5.288 4.759l8.907-9.896a30.074 30.074 0 0 0 7.73-20.146Zm99.248 31.766a3.558 3.558 0 0 0-4.342 2.54 129.027 129.027 0 0 1-4.856 14.825 3.556 3.556 0 0 0 3.306 4.868 3.558 3.558 0 0 0 3.308-2.247 136.493 136.493 0 0 0 5.125-15.645 3.557 3.557 0 0 0-2.541-4.341Zm-11.84-87.983a3.556 3.556 0 1 0-4.757 5.29c10.463 9.405 16.464 22.839 16.464 36.857v15.587c0 5.915-.408 11.876-1.213 17.718a3.556 3.556 0 1 0 7.047.97c.849-6.162 1.28-12.45 1.28-18.689v-15.586c0-16.033-6.86-31.395-18.821-42.147Zm-16.628-2.64a3.558 3.558 0 0 0 3.059-6.423 56.19 56.19 0 0 0-16.19-4.894 3.558 3.558 0 0 0-1.012 7.042 49.094 49.094 0 0 1 14.143 4.275Z" />
        <path d="M230.396 169.582a73 73 0 0 0-4.001.11 3.557 3.557 0 0 0-3.356 3.747 3.55 3.55 0 0 0 3.747 3.356 65.741 65.741 0 0 1 3.61-.099c35.696 0 64.738 29.041 64.738 64.738v15.586a3.556 3.556 0 1 0 7.114 0v-15.586c0-39.619-32.233-71.852-71.852-71.852ZM191.5 188.881c.7 0 1.408-.207 2.027-.637a64.351 64.351 0 0 1 19.319-9.134 3.557 3.557 0 1 0-1.93-6.847 71.448 71.448 0 0 0-21.45 10.139 3.557 3.557 0 0 0 2.034 6.479Zm-25.84 66.617v-14.073c0-16.547 6.266-32.306 17.645-44.375a3.558 3.558 0 0 0-5.176-4.88c-12.629 13.394-19.584 30.887-19.584 49.255v14.073a7.808 7.808 0 0 1-2.008 5.233l-4.386 4.874a3.557 3.557 0 0 0 5.288 4.759l4.387-4.873a14.92 14.92 0 0 0 3.834-9.993Zm147.245-41.766c-3.901-11.594-10.101-22.068-18.428-31.132a3.557 3.557 0 1 0-5.239 4.813c7.649 8.326 13.343 17.944 16.925 28.588a3.56 3.56 0 0 0 4.506 2.236 3.556 3.556 0 0 0 2.236-4.505Zm-54.401-54.686a3.557 3.557 0 0 0-2.303 6.731 79.663 79.663 0 0 1 21.888 11.557 3.54 3.54 0 0 0 2.125.706 3.557 3.557 0 0 0 2.13-6.408 86.833 86.833 0 0 0-23.84-12.586Z" />
        <path d="M175.126 183.75c14.963-14.346 34.591-22.247 55.269-22.247 3.839 0 7.701.276 11.475.821a3.555 3.555 0 0 0 4.029-3.013 3.555 3.555 0 0 0-3.012-4.028 87.795 87.795 0 0 0-12.492-.894c-22.522 0-43.899 8.603-60.193 24.226-16.243 15.574-25.742 36.474-26.747 58.85a3.557 3.557 0 1 0 7.107.319c.922-20.541 9.646-39.731 24.564-54.034Z" />
        <path d="M230.393 139.223c-7.955 0-15.881.922-23.559 2.74a3.557 3.557 0 1 0 1.639 6.922 95.312 95.312 0 0 1 21.92-2.548c26.083 0 50.43 10.392 68.556 29.26a3.548 3.548 0 0 0 2.566 1.093 3.558 3.558 0 0 0 2.565-6.021c-9.444-9.831-20.538-17.565-32.973-22.985-12.879-5.615-26.577-8.461-40.714-8.461Zm-79.898 44.289a3.544 3.544 0 0 0 2.191.756 3.551 3.551 0 0 0 2.803-1.364c10.137-12.948 23.483-23.105 38.595-29.372a3.558 3.558 0 0 0-2.726-6.572c-16.239 6.735-30.58 17.648-41.47 31.558a3.556 3.556 0 0 0 .607 4.994Zm30.579-41.103c.519 0 1.046-.114 1.544-.355 14.976-7.231 31.051-10.897 47.777-10.897a110.37 110.37 0 0 1 25.801 3.048 3.558 3.558 0 0 0 1.664-6.917 117.54 117.54 0 0 0-27.465-3.245c-17.806 0-34.921 3.904-50.87 11.605a3.558 3.558 0 0 0 1.549 6.761Zm89.989-3.494a109.9 109.9 0 0 1 10.787 4.99 3.558 3.558 0 0 0 3.331-6.286 117.283 117.283 0 0 0-11.487-5.313 3.558 3.558 0 0 0-4.621 1.988 3.559 3.559 0 0 0 1.99 4.621Z" />
      </g>
      <rect
        fill={darkBackground ? black : white}
        height="409.003"
        opacity=".9"
        rx="0"
        width="457"
        y="322.134"
      >
        <animate attributeName="y" dur="8s" repeatCount="indefinite" values="322.134, -330" />
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          dur="8s"
          repeatCount="indefinite"
          type="translate"
          values="0 320; 0 110; 0 320"
        />
      </rect>
      <path d="M97.133 322.134h262.275" stroke="#F60" strokeLinecap="round" strokeWidth="6.329">
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          dur="8s"
          repeatCount="indefinite"
          type="translate"
          values="0 0; 0 -210; 0 0"
        />
      </path>
      <defs>
        <clipPath id="dark-animated-fingerprint_svg__a">
          <path
            d="M0 0h219.588v219.588H0z"
            fill={darkBackground ? black : white}
            transform="translate(122.525 124.043)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
