import { css } from '@emotion/react';
import { AccordionDetails, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type {
  MarkdownRemarkFrontmatterSecurityPointsPointCards,
  Maybe,
} from '../../../generated/types';
import { LearnMoreButton } from '../../components/buttons';
import { MarkdownTypography } from '../../components/typography';

type PpSecurityAccordionDetails = {
  body: string | undefined | null;
  cards: Maybe<Maybe<MarkdownRemarkFrontmatterSecurityPointsPointCards>[]> | undefined;
  intro: string | undefined | null;
};

export function SecurityAccordionDetails({
  body,
  cards,
  intro,
}: PpSecurityAccordionDetails): React.ReactElement {
  const theme = useTheme();

  const styles = {
    accordionDetails: css`
      padding: 0;
      a {
        color: ${theme.palette.websiteBrand.primary};
      }
    `,
    accordionDetailsCard: css`
      padding-bottom: ${theme.spacing(2)};
    `,
    accordionDetailsContainer: css`
      padding: ${theme.spacing(2, 0)};
    `,
  };
  return (
    <AccordionDetails css={styles.accordionDetails}>
      <Stack>
        <Stack css={styles.accordionDetailsContainer} spacing={4}>
          {intro && <Typography variant="paragraphBodyL">{intro}</Typography>}
          {body && (
            <MarkdownTypography color="text.dark" variant="paragraphBodyL">
              {body}
            </MarkdownTypography>
          )}
          {Array.isArray(cards) && cards.length > 0 && (
            <Stack spacing={2}>
              {cards.map((card) => {
                if (!card) {
                  return null;
                }
                return (
                  <Stack
                    key={card.id}
                    alignItems="flex-start"
                    css={styles.accordionDetailsCard}
                    spacing={1}
                  >
                    <Typography variant="paragraphBodyL">{card?.pointCardTitle}</Typography>
                    <MarkdownTypography color="text.dark" variant="paragraphBodyL">
                      {card?.pointCardBody}
                    </MarkdownTypography>
                    {card?.pointCardLink && (
                      <LearnMoreButton color="primary" href={card?.pointCardLink} size="small" />
                    )}
                  </Stack>
                );
              })}
            </Stack>
          )}
        </Stack>
      </Stack>
    </AccordionDetails>
  );
}
